<template>
  <div class="box">
    <!-- 合同文件管理查看详情 -->
    <backtitle lable="合同文件管理" />
    <div class="content">
      <div class="fileChoiceBox" v-if="fileList.length !== 0">
        <!-- 文件选择栏 -->
        <file-menu :fileList='fileList' :activeId.sync='activeId' @changeFile='changeFile' :openedArr='openeds' @handleClick='handleClick' />
      </div>
      <!-- 文件预览 -->
      <div style="flex:1">
        <div class="doloadBtn">
           <div style="flex:1"><div v-if="fileSource">文件来源：{{fileSource==='0'?'线上生成':'人工上传'}}</div></div>
          <base-button
            icon="iconfont iconxiazai"
            label="文件下载"
            @click="downFile(fileId)"
            v-if="preViewShow"
          />
        </div>
        <div class="preView" :style="maxheight" >
          <pre-view
            :fileId="fileId"
            :fileType="fileType"
            :count="count"
            v-if="preViewShow"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import fileMenu from './components/file-menu.vue'
import baseButton from '@/components/common/button/base-button/base-button.vue'
import { fileListApi } from '@/api/businessApi'
import { downAPi } from '@/api/fileApi'
import { downFile } from '@/utils/devUtils'
import backtitle from '../components/backtitle.vue'
import PreView from '@/components/pre-view/pre-view.vue'
import { allSupportFileTypes } from '@/components/pre-view/pre-view.js'
import Storage from '@/utils/storage'
export default {
  components: { backtitle, PreView, baseButton, fileMenu },
  data () {
    return {
      preViewShow: true,
      openeds: ['0', '0-0'],
      count: 0,
      fileId: '',
      fileType: '',
      infoData: {},
      rowData: {},
      fileList: [
        {
          label: '合同文件',
          key: 'htwj',
          child: [],
          id: '1'
        },
        { label: '其他资料', child: [], key: 'qtzl', id: '2' },
        { label: '基础资料', child: [], key: 'jczl', id: '3' }
      ],
      activeNames: '1',
      activeId: '',
      maxheight: {
        height: '100%'
      },
      fileSource: '',
      downloadZipId: ''

    }
  },
  computed: {
    api () {
      return fileListApi
    }
  },
  methods: {
    // 文件下载
    downFile (fileId) {
      if (fileId || this.downloadZipId) {
        const params = { keyId: this.downloadZipId ? this.downloadZipId : fileId }

        downAPi
          .download(params)
          .then((res) => {
            downFile(res)
          })
          .catch((e) => {
            this.error(e)
          })
      } else {
        this.error('抱歉暂无资源')
      }
    },
    // 选中文件
    changeFile (elem) {
      this.activeId = elem.currentFileId
      this.fileId = elem.currentFileId
      this.fileSource = elem.fileSource
      this.fileType = elem.currentFileFileSuffix
      this.downloadZipId = elem.pdfFileId && elem.pdfFileId !== '0' ? elem.fileId : ''
      if (elem.currentFileId.toString() === '0') {
        this.warning('未找到文件')
        this.preViewShow = false
        return
      }

      if (!this.fileType || !allSupportFileTypes.includes(this.fileType)) {
        this.warning(
          elem.suffix
            ? `不能预览的文件类型：[${this.fileType}]`
            : '文件类型不能为空'
        )
        this.preViewShow = false
        return
      }

      this.count++
      this.preViewShow = true
    },
    // 获取文件信息
    getdata () {
      const params = {
        businessId: Storage.getSession('examine').keyId,
        instanceId: Storage.getSession('examine').instanceId,
        batchNum: Storage.getSession('examine').batchNum || null
      }

      this.api.getContracthandle(params).then((res) => {
        this.finReviewOpinionInfoVOS = res.data.finReviewOpinionInfoVO
        if (res.data.finFileListInfoVO) {
          res.data.finFileListInfoVO.forEach((item) => {
            this.fileList.forEach((elem) => {
              if (item.fileGroupCode === elem.key) {
                elem.child.push(item)
              }
            })
          })
          this.changeFile(this.fileList[0].child[0].fileListDetailVOS[0])
        }
      })
    },
    handleClick (item, indexPath) {
      // const height = item.label === '其他资料' ? 46 : 45
      if (indexPath.length > 1) {
        let key = indexPath[1]
        key = key.split('-')
        key = key[key.length - 1]
        this.changeFile(this.fileList[indexPath[0]].child[key].fileListDetailVOS[0])
      }
      // this.$nextTick(() => {
      //   var clientHeight = this.$refs.tabs.$el.clientHeight
      //   this.maxheight.height =
      //     'calc(100% - ' + (Number(clientHeight) + height) + 'px)'
      // })
    }
  },
  mounted () {
    this.getdata()
  }
}
</script>
<style lang="scss" src='./contract-file-manage-info.scss' scoped>

</style>
