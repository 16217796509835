<template>
<!-- 文件选择栏 -->
  <div>
     <!-- <el-menu
          :default-openeds="openedArr"
          class="el-menu-vertical-demo"
          v-if="fileList.length !== 0"
          @select="handleClick"
        >
          <el-submenu
            :index="index.toString()"
            v-for="(item, index) of fileList"
            :key="index"
            v-show="item.child.length>0"
          >
            <template slot="title">
              <span>{{ item.label }}</span>
            </template>

            <el-menu-item
              :index="index + '-' + indexon"
              v-for="(elem, indexon) of item.child"
              :key="indexon"
              @click="changeFile(elem)"
              :class="{ active: elem.documentId === activeId }"
            >
              {{ elem.documentName }}
            </el-menu-item>
          </el-submenu>
        </el-menu> -->
           <el-menu
          :default-openeds="openedArr"
          class="el-menu-vertical-demo"
          v-if="fileList.length !== 0 "
          @open="handleClick"
          :unique-opened="true"
        >
          <el-submenu
            :index="index.toString()"
            v-for="(item, index) of fileList"
            :key="index"
            v-show="item.child && item.child.length > 0"
          >
            <template slot="title">
              <span >{{ item.label }}</span>
            </template>
            <el-submenu
              :index="index + '-' + indexon"
              v-for="(elem, indexon) of item.child"
              :key="indexon"
              class="SmallTitle"
               :class="{titleSpan:titleSpan(elem.documentName)}"
            >
              <template slot="title">{{ elem.documentName }}</template>
              <el-menu-item
                v-for="(objKey, key) of elem.fileListDetailVOS"
                :key="key"
                :index="index + '-' + indexon"
                @click="changeFile(objKey)"
                :class="{ active: objKey.currentFileId === activeId }"
              >
               <el-tooltip class="item" effect="dark" :content="objKey.fileName" placement="top" :enterable='false'>
      <div class="objKeyFileName">{{ objKey.fileName }}</div>
    </el-tooltip>

              </el-menu-item>
            </el-submenu>
          </el-submenu>
        </el-menu>
  </div>
</template>
<script>
export default {
  props: {
    fileList: {
      type: Array,
      default: () => []
    },
    openedArr: {
      type: Array,
      default: () => []
    },
    activeId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {

    }
  },
  methods: {
    titleSpan (fileName) {
      return fileName.length > 16
    },
    handleClick (item, index) {
      this.$emit('handleClick', item, index)
    },
    changeFile (elem) {
      this.$emit('changeFile', elem)
    }

  }
}
</script>
<style lang="scss" scoped>
/deep/.titleSpan{
  .el-submenu__title{
    height: auto;
  line-height: 33px;
  white-space: normal;
  }

}
</style>
